// TODO: move this file into /utils folder

import moment from 'moment';

export function stripNonNumeric(value, limit) {
	return (value || '')
		.toString()
		.replace(/\D/g, '')
		.substr(0, limit || 40);
}

export function isOutsideClick(e, dom) {
	if (dom === null) {
		return true;
	}
	const componentOffset = dom.getBoundingClientRect();
	return (
		e.clientX < componentOffset.left ||
		e.clientX > componentOffset.left + componentOffset.width ||
		e.clientY < componentOffset.top ||
		e.clientY > componentOffset.top + componentOffset.height
	);
}

/**
 *
 * @param file
 * @returns {Promise<string base64>}
 */
export function fileToBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = error => {
			reject(error);
		};
	});
}

/**
 *
 * @param files -> An array of files
 * @returns {Promise<boolean>}
 */
export function validateFileSize(files, sizeFileLimit, previousFiles) {
	return new Promise(async (resolve, reject) => {
		// Aux
		let filesTotalSize = 0;
		let allFiles = [];
		if (previousFiles) {
			allFiles = [...previousFiles, ...Array.from(files)];
		} else {
			allFiles = Array.from(files);
		}
		// Pass thru each file element and verify size
		const promissesFiles = Array.from(allFiles).map(async file => {
			filesTotalSize += file.size;
		});
		await Promise.all(promissesFiles);
		// If size is ok, return true, else return false
		if (filesTotalSize < sizeFileLimit) {
			return resolve(true);
		} else {
			return reject(false);
		}
	});
}
/**
 * @param index index of the word
 * @param sentence
 * @returns {Promise<boolean>}
 */
export function findWordAtIndex(index, sentence) {
	index--;
	// if the word is empty or something weird happened with the index just return null
	if (!sentence || sentence.length === 0 || !sentence[index]) {
		return null;
	}

	// split the sentence into an array
	const words = sentence.split(' ');
	let characterCount = 0;

	// then count each word of that array until fi the word that the user is in
	for (let i = 0; i < words.length; i++) {
		const word = words[i];

		characterCount += word.length;

		if (characterCount > index) {
			return word;
		}

		// Add 1 to account for the space between words
		characterCount += 1;
	}

	return null;
}

export function isISOString(value) {
	// Check if the value is a string
	if (typeof value !== 'string') {
		return false;
	}

	// Validate the string using the ISO 8601 format
	return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z?$/.test(value);
}

export function formatDateAnyFormat(date, type) {
	let auxDateObj = moment(date);

	if (type === 'data') {
		auxDateObj = auxDateObj.format('DD/MM/YYYY');
	} else if (type === 'datehour') {
		auxDateObj = auxDateObj.format('DD/MM/YYYY HH:mm');
	} else {
		// just default to date hour
		auxDateObj = auxDateObj.format('DD/MM/YYYY HH:mm');
	}

	return auxDateObj;
}

export const filterWhatsappConfig = (item, whatsappConfig, whatsLightConfig) => {
	const hasNoWhatsappConfig = item.key === 'whatsapp' && !whatsappConfig;
	const hasNoWhatsLightConfig = item.key === 'pickyassist' && !whatsLightConfig;
	return !hasNoWhatsappConfig && !hasNoWhatsLightConfig;
};

export const getOwnerFieldsLabel = (fieldKey, fieldConfig) => {
	let defaultValues = {
		groupSalesId: 'Responsável',
		groupId: 'PDV',
		teamId: 'Equipe',
	};
	if (fieldConfig?.length) {
		let field = fieldConfig.find(item => item.key === fieldKey);
		if (field) return field.label;
	}
	return defaultValues[fieldKey];
};

// function to download the file with a specific name
export const handleDownloadCsv = async (fileAddress, fileName) => {
	const blob = await fetch(encodeURI(fileAddress)).then(r => r.blob());
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = url;
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);

	a.remove();
};
